import React from 'react'
import HeaderContent from './shared/HeaderContent'

export default function Header({ openTab, location, locale, locationPath, insideProject, slug }) {
  return (
    <HeaderContent
      slug={slug}
      insideProject={insideProject}
      locationPath={locationPath}
      locale={locale}
      location={location}
      className={openTab === null ? 'header headerBorder' : 'header headerBorderDark headerDark'}
    />
  )
}
