import React from 'react'
import { LinkButton } from '../buttons/Button'
import Logo from './shared/Logo'
import FooterMediaAndCopyrights from './shared/FooterMediaAndCopyrights'
import FooterProjectsList from './shared/FooterProjectsList'
import getFooter from '../../hooks/useFooterQuery'
import { getItemsByLocale } from '../../utils'

export default function Footer({ location, locale }) {
  const { mediaList, buttons } = getFooter()
  const newProject = getItemsByLocale(locale, mediaList.edges)
  const buttonName = getItemsByLocale(locale, buttons.edges)
  const newProjectItem = newProject[0].node.frontmatter.footer.newProject
  const { contactButton } = buttonName[0].node.frontmatter
  const { footer } = mediaList.edges[0].node.frontmatter
  return (
    <footer className="footer">
      <div className="container">
        <div className="contactUsIpad">
          <p className="text">{newProjectItem}</p>
          <div className="buttonAling">
            <LinkButton name={contactButton} color="light" link="/contacts" />
          </div>
        </div>
        <div className="footer-container">
          <div className="contactUs ">
            <div className="box">
              <p className="text">{newProjectItem}</p>
              <LinkButton name={contactButton} color="light" link="/contacts" />
            </div>
            <div className="logo">
              <Logo locale={locale} />
            </div>
          </div>
          <FooterProjectsList location={location} locale={locale} media={mediaList} />
        </div>
        <FooterMediaAndCopyrights locale={locale} media={mediaList} copyright={footer} />
      </div>
    </footer>
  )
}
