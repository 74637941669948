import React from 'react'
import Footer from './Footer'
import Header from './Header'

export default function Layout({
  children,
  openTab,
  location,
  locale,
  locationPath,
  insideProject,
  slug,
}) {
  return (
    <>
      <Header
        insideProject={insideProject}
        openTab={openTab}
        location={location}
        locale={locale}
        locationPath={locationPath}
        slug={slug}
      />
      {children}
      <Footer location={location} locale={locale} />
    </>
  )
}
