import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { BgImage } from 'gbimage-bridge'
import { getImage } from 'gatsby-plugin-image'
import Layout from '../components/layout/Layout'
import getServices from '../hooks/useLandingPageItemsQuery'
import { filterById, getSlug, showNumber } from '../utils'

export default function Services({ location, data, pageContext }) {
  const { allServices } = getServices()
  const { title, services } = data.markdownRemark.frontmatter
  const servicesFilter = filterById(allServices, services).sortByNumber()
  const [selected, setSelected] = useState(null)
  const toggle = i => {
    if (selected === i) {
      return setSelected(null)
    }
    setSelected(i)
  }
  return (
    <Layout location={location} locale={pageContext.locale} locationPath={pageContext.locationPath}>
      <div className="servicesPageContainer">
        <h1 className="pageTitle">{title}</h1>
        <div className="servicesContainer">
          {servicesFilter.map(({ node }, i) => (
            <div
              className={selected === i ? 'serviseBoxWrapper open' : 'serviseBoxWrapper'}
              key={i}
              id={getSlug(node.fields.slug)}
            >
              <BgImage image={getImage(node.frontmatter.imageWide)}>
                <div className="serviseBox">
                  <div className="leftSide">
                    <div className="leftSide-box">
                      <span>[{showNumber(i + 1)}]</span>
                      <div className="handlerBox">
                        <h1>{node.frontmatter.title}</h1>
                        <span
                          className="service-icon"
                          onClick={() => toggle(i)}
                          role="presentation"
                        ></span>
                      </div>
                    </div>
                  </div>
                  <p className="rightSide">{node.frontmatter.description}</p>
                </div>
              </BgImage>
              <p className="serviceText">{node.frontmatter.description}</p>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        services
      }
    }
  }
`
